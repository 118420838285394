import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import Home from "./components/Home";
import Manufacturer from "./components/Manufacturer";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import About from "./components/About";
import DataPolicy from "./components/DataPolicy";
import Imprint from "./components/Imprint";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import AddOrder from "./components/Orders/Add";
import Search from "./components/Orders/Search";
import OrderView from "./components/OrderView";

import Footer from "./components/layout/Footer";
import MUINav from "./components/layout/MUINav";
import { Route, Switch, useLocation } from "react-router-dom";
import AuthRoute from "./utils/AuthRoute";
import ProtectedRoute from "./utils/ProtectedRoute";

import { loadCurrentUser, logout } from "./redux/ducks/auth";
import { openCookieDialog } from "./redux/ducks/cookieConsentDialog";
import DynAppbar from "./components/layout/DynAppBar";
import MUIDrawer from "./components/layout/MUIDrawer";
import Forgot from "./components/auth/Forgot";
import Reset from "./components/auth/Reset";
import MyOrders from "./components/MyOrders";
import MyExpiredOrders from "./components/MyExpiredOrders"
import CompanyProfile from "./components/CompanyProfile";
import ReceivedOffers from "./components/Offers/Received";
import SentOffers from "./components/Offers/Sent";
import OfferView from "./components/Offers/View";
import Settings from "./components/Settings";
import NotFound from "./components/NotFound";
import Unverified from "./components/Unverified";
import VerifyUser from "./components/VerifyUser";
import Snack from "./components/Snack";
import ConsentDialog from "./components/ConsentDialog";
import NDA from "./components/NDA";
// import Test from "./components/Test";
import Unsubscribed from "./components/Unsubscribed";
import StripeSuccess from "./components/stripe/Success";
import StripeFailure from "./components/stripe/Failure";
import socket from "./helpers/socket";
import store from "./redux/store";
import { addNewNotifications } from "./redux/ducks/notifications";
import { receiveMessage } from "./redux/ducks/messages";
import Conversations from "./components/Conversations";

const dynamicPages = [
  "orders",
  "offers",
  "companies",
  "settings",
  "conversations",
];

socket.on("new-notification", (n) => {
  store.dispatch(addNewNotifications(n));
});
socket.on("new-message", (message) => {
  const currentConv = store.getState().conversations.currentConversationId;
  // if current conv is true, message will be added to the end of the current msgs
  const isCurrentConv = Boolean(
    message.conversation._id.toString() === currentConv
  );
  store.dispatch(
    receiveMessage({
      message,
      isCurrentConv,
    })
  );
  if (isCurrentConv) {
    socket.emit("conversation-checked", message);
  }
});

const App = () => {
  const [isStatic, setStatic] = React.useState(false);
  let location = useLocation();
  const dispatch = useDispatch();

  //cookie dialog
  const { accepted } = useSelector((s) => s.cookieConsent);
  const basicCookiesAccepted = localStorage.getItem("necessary");
  const analyticsCookiesAccepted = localStorage.getItem("statistics");
  React.useEffect(() => {
    if (!basicCookiesAccepted) {
      return dispatch(openCookieDialog());
    }
    // if already accepted necessary & rejected additional: don't initiate GA
    if (!analyticsCookiesAccepted) {
      return;
    }
    window.dataLayer.push({ event: "enable-analytics" });
  }, [basicCookiesAccepted, analyticsCookiesAccepted, dispatch, accepted]);
  //

  React.useEffect(() => {
    // checks location every time page loads, then checks if it is static, then updates state
    if (
      dynamicPages.includes(
        location.pathname.toLowerCase().toString().split("/")[1]
      )
    ) {
      setStatic(false);
    } else {
      setStatic(true);
    }
  }, [location]);

  React.useEffect(() => {
    // checks if there is a user in localStorage.
    const user = localStorage.getItem("user");
    // If user is found in the localStorage, check with server if it matches the session.
    if (user) {
      // take the user and company from server response and add to the state.
      dispatch(loadCurrentUser());
    } else {
      dispatch(logout(false));
    }
  }, [dispatch]);

  // if http redirect to https (only production)
  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (window.location.protocol === "http:") {
        window.location.protocol = "https:";
      }
    }
  });

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: isStatic ? "column" : "row",
      }}
    >
      {isStatic ? <MUINav /> : <DynAppbar />}
      {!isStatic && <MUIDrawer />}
      <Snack />
      <ConsentDialog />
      <div className={isStatic ? "staticPage" : "all"}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/manufacturer" exact component={Manufacturer} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/data-policy" component={DataPolicy} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/imprint" component={Imprint} />
		  <Route path="/nda" component={NDA} />
          {/* <Route path="/test" component={Test} /> */}
          <AuthRoute path="/register" component={Register} />
          <AuthRoute path="/login" component={Login} />
          <AuthRoute path="/forgot" component={Forgot} />
          <AuthRoute path="/reset/:token" component={Reset} />
          <Route // internally redirected
            path="/unverified"
            component={Unverified}
          />
          <ProtectedRoute path="/unsubscribed" component={Unsubscribed} />
          <ProtectedRoute path="/stripe-failure" component={StripeFailure} />
          <ProtectedRoute path="/stripe-success" component={StripeSuccess} />
          <ProtectedRoute path="/verify/:token" component={VerifyUser} />
          <ProtectedRoute
            path="/companies/:id"
            exact
            component={CompanyProfile}
          />
          <ProtectedRoute path="/orders/my" exact component={MyOrders} />
          <ProtectedRoute path="/orders/new" exact component={AddOrder} />
          <ProtectedRoute path="/orders/expired" exact component={MyExpiredOrders} />
          <ProtectedRoute
            path="/orders/search"
            manufOnly
            exact
            component={Search}
          />
          <ProtectedRoute path="/orders/:id" exact component={OrderView} />
          <ProtectedRoute
            path="/offers/received"
            exact
            component={ReceivedOffers}
          />
          <ProtectedRoute
            path="/offers/sent"
            manufOnly
            exact
            component={SentOffers}
          />
          <ProtectedRoute path="/offers/:id" exact component={OfferView} />
          <ProtectedRoute path="/settings" exact component={Settings} />
          <ProtectedRoute
            path="/conversations"
            exact
            component={Conversations}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
      {isStatic && <Footer />}
    </div>
  );
};
export default App;

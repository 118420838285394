import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import OwnLink from "../shared/Link";

function NDA() {
	return (
	<Container>
		<p>
<Typography align="center" variant="h1">
	Geheimhaltungsvereinbarung
</Typography>
		</p>
		<br />
		<p>
1. Der Geltungsbereich der gegenständlichen Geheimhaltungsvereinbarung
(in weiterer Folge auch &raquo;Vereinbarung&laquo;), erstreckt sich auf die geplante
Zusammenarbeit im Bereich
		</p>
		<br />
		<p>
<Typography align="center" variant="h6">
	Gesamte Zusammenarbeit
</Typography>
		</p>
		<br />
		<p>
(in weiterer Folge auch &raquo;Projekt&laquo;) zwischen den Vertragsparteien
(in weiterer Folge auch &raquo;Parteien&laquo;). 
		</p>
		<br />
		<p>
2. Jede Partei verfügt über gewisse, sich in ihrem Eigentum 
befindliche Schutzrechte, Daten und sonstige Informationen. Hierbei 
handelt es sich insbesondere aber nicht ausschließlich um technische 
und/oder wirtschaftliche Informationen sowie Schutzrechte und 
Knowhow, welche diese Partei als vertraulich betrachtet. Die 
erwähnten Informationen (Schutzrechte, Knowhow, Daten und sonstige 
Informationen) sowie Schutzrechte, Knowhow, Daten und sonstige 
Informationen, welche im Verlauf des Projektes von einer Partei 
und/oder einem ihrer Mitarbeiter entwickelt werden, sind von der 
gegenständlichen Vereinbarung umfasst und werden in weiterer Folge 
auch als &raquo;Information&laquo; bezeichnet. Es wird ausdrücklich festgehalten, 
dass der Begriff Information im Sinne der gegenständlichen 
Vereinbarung sämtliche Information von Order Scout umfasst. 
		</p>
		<br />
		<p>
3. Keine Partei wird weder direkt noch indirekt Information der 
jeweils anderen Partei zu einem anderen Zweck als zur gemeinsamen 
Vorbereitung und/oder Durchführung von Projekten verwenden ohne 
vorher eine ausdrückliche schriftliche Zustimmung der anderen Partei 
erhalten zu haben.
		</p>
		<br />
		<p>
4. Die Parteien beabsichtigen, der jeweils anderen Partei Information 
zur Durchführung von Projekten – auf welche Weise auch immer – 
zugänglich zu machen. Die Parteien vereinbaren und anerkennen, dass 
jede Ermöglichung des Zuganges zu Information der jeweils anderen 
Partei sowie jeder Austausch von Information im Verlauf und/oder im 
Vorfeld der Durchführung des Projektes stets auf der Grundlage und 
unter Berücksichtigung der gegenständlichen Vereinbarung erfolgt. 
		</p>
		<br />
		<p>
5. Jede Information bleibt im alleinigen und ausschließlichen 
Eigentum der jeweils gebenden Partei. Die empfangende Partei erhält 
aus erhaltener Information der jeweils anderen Partei keine wie auch 
immer gearteten Ansprüche, insbesondere keine Eigentums-, Nutzungs- 
oder Verwertungsrechte. Information, welche im Verlauf des Projektes 
von einer Partei oder einem ihrer Mitarbeiter entwickelt wurde, 
verbleibt im ausschließlichen und alleinigen Eigentum dieser Partei. 
		</p>
		<br />
		<p>
6. Die Parteien kommen ferner überein, dass eine direkte Ansprache 
des Order Scout Kunden im konkreten Projekt durch den Vertragspartner 
nur mit Zustimmung von Order Scout erfolgen kann. Die gleiche 
Vorgehensweise der direkten Ansprache im konkreten Projektfall gilt 
für die Kunden des Vertragspartners, die ebenfalls nur mit Zustimmung 
des Vertragspartners angesprochen werden dürfen.
		</p>
		<br />
		<p>
7. Informationen zum Marktumfeld, über Marktbegleiter und über Order 
Scout selbst dürfen vom Vertragspartner nur mit Zustimmung von Order 
Scout öffentlich gemacht werden. Gleiches gilt für Informationen des 
Vertragspartners zum Marktumfeld, über Marktbegleiter und den 
Vertragspartner selbst. Diese Informationen dürfen ebenfalls nur mit 
Zustimmung des Vertragspartners öffentlich gemacht werden.
		</p>
		<br />
		<p>
8. Die gegenständliche Vereinbarung tritt mit dem Abschluss der Registrierung
als Einkäufer oder Fertiger in Kraft und wird für einen Zeitraum von 
sechsunddreißig (36) Monaten abgeschlossen. Sie erneuert sich 
stillschweigend jeweils um weitere zwölf (12) Monate, wenn sie nicht 
unter Einhaltung einer Kündigungsfrist von neun (9) Monaten zum Ende 
eines Vertragsjahres schriftlich gekündigt wird.
		</p>
		<p>
Die in der gegenständlichen Vereinbarung festgelegten 
Benützungsbeschränkungen und Verpflichtungen zur Geheimhaltung 
bleiben auch nach der Beendigung der gegenständlichen Vereinbarung 
aufrecht. 
		</p>
		<br />
		<p>
9. Auf Verlangen der gebenden Partei oder im Falle der Beendigung der 
gegenständlichen Vereinbarung wird jede Partei sämtliche Dokumente, 
Akten, Dateien, Kopien und sonstigen Aufzeichnungen von Information 
der gebenden Partei unverzüglich an die gebende Partei zurücksenden 
oder – wiederum auf Verlangen der gebenden Partei – diese Unterlagen 
vernichten und der gebenden Partei eine schriftliche Bestätigung über 
die Vernichtung der Unterlagen übermitteln. 
		</p>
		<br />
		<p>
10. Änderungen und Ergänzungen der gegenständlichen Vereinbarung 
bedürfen zu ihrer Wirksamkeit der Schriftform und sind von beiden 
Parteien firmenmäßig zu unterfertigen. Mündliche Nebenabreden sind 
unwirksam.
		</p>
		<br />
		<p>
11. Beide Parteien garantieren und gewährleisten, dass keiner ihrer 
Gesellschafter, Mitarbeiter, Manager, Direktoren und Geschäftspartner 
sowie keines ihrer Tochter-, Schwester- und Mutterunternehmen, deren 
Eigentümer, Mitarbeiter, Manager, Direktoren und Geschäftspartner ein 
Verhalten setzen werden, welches eine Verletzung der gegenständlichen 
Vereinbarung darstellen würde.
		</p>
		<br />
		<p>
12. Die gegenständliche Vereinbarung ist verbindlich und zum Nutzen 
beider Parteien sowie deren jeweiligen Rechtsnachfolgern 
abgeschlossen. Die Ungültigkeit oder Unwirksamkeit einer Bestimmung 
oder eines Teiles einer Bestimmung dieser Vereinbarung berührt nicht 
die Gültigkeit oder Wirksamkeit irgendeiner anderen Bestimmung oder 
eines Teiles einer Bestimmung dieser Vereinbarung.
		</p>
		<br />
		<p>
13. Keine Partei ist berechtigt, die gegenständliche Vereinbarung 
ohne vorherige schriftliche Zustimmung der anderen Partei einem 
Dritten zu übertragen oder abzutreten.
		</p>
		<br />
		<p>
14. Für sämtliche im Zusammenhang mit der gegenständlichen 
Vereinbarung sowie im Rahmen der gegenwärtigen und zukünftigen 
Geschäftsbeziehungen zwischen den Parteien entstehenden 
Streitigkeiten vereinbaren beide Parteien die ausschließliche 
Zuständigkeit des jeweils sachlich zuständigen Gerichtes in Köln, 
Deutschland. Es gilt ausnahmslos deutsches Recht.
		</p>
	</Container>
	);
}

export default NDA;

// dependencies
import React from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";

// MUI
import TextField from "@mui/material/TextField";
import { materials, certificates } from "../../../fixedInput";
import makeStyles from "@mui/styles/makeStyles";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// imports
import { cleanUp, submitOrder } from "../../../redux/ducks/addOrder";
import { openSnack } from "../../../redux/ducks/snackbar";
import { CircularProgress, Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "start",
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
  },
  fileSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    maxWidth: "250px",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
  },
}));

const technologies = [
  "3-Achs-CNC-Fräsen",
  "5-Achs-CNC-Fräsen",
  "Additive Fertigung / 3D Druck",
  "Auftragschweißen",
  "Drehen",
  "Drehfräsen",
];

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    sx={{ maxWidth: "100%" }}
    {...input}
    {...custom}
  />
);
const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, invalid, error },
  ...props
}) => {
  const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files);
  return (
    <div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      <FormHelperText error>{touched && error}</FormHelperText>
    </div>
  );
};

// const renderFormHelper = ({ touched, error }) => {
//   if (!(touched && error)) {
//     return;
//   } else {
//     return <FormHelperText>{touched && error}</FormHelperText>;
//   }
// };

// const renderSelectField = ({
//   input,
//   label,
//   meta: { touched, error },
//   children,
//   ...custom
// }) => (
//   <FormControl
//     variant="outlined"
//     style={{ width: "100%" }}
//     error={!!touched && !!error}
//   >
//     <InputLabel id={label}>{label}</InputLabel>
//     <Select
//       labelId={label + "Id"}
//       id={label}
//       label={label}
//       {...input}
//       {...custom}
//     >
//       {children}
//     </Select>
//     {renderFormHelper({ touched, error })}
//   </FormControl>
// );

const RenderedAutocomplete = (field) => {
  const [val, setVal] = React.useState(null);
  return (
    <Autocomplete
      style={{ width: "100%" }}
      freeSolo
      options={field.options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={field.label}
            error={!!field.meta.touched && field.meta.invalid}
            helperText={!!field.meta.touched && field.meta.error}
          />
        );
      }}
      onInputChange={(e, value) => {
        setVal(value);
        field.changeFn(field.input.name, value);
      }}
      value={val}
    />
  );
};

const AddOrdersForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, loaded, error, order } = useSelector(
    (state) => state.addOrder
  );

  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);

  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Auftrag wurde erfolgreich hinzugefügt"));
      history.push(`/orders/${order._id}`);
      dispatch(cleanUp());
    }
  }, [dispatch, loaded, order, history]);

  return (
    <form
      onSubmit={props.handleSubmit((values) => {
        dispatch(submitOrder(values));
      })}
    >
      <Typography variant="h4" component="h2">
        {"Auftrag Zusammenfassung"}
      </Typography>

      <div className={classes.root}>
        <Field
          name="title"
          component={renderTextField}
          label="Auftrag Titel*"
          variant="outlined"
          className={classes.textField}
          fullWidth
        />

        <Field
          name="description"
          component={renderTextField}
          label="Auftrag Beschreibung*"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          className={classes.textField}
        />

        <Field
          name="qty"
          component={renderTextField}
          label="Menge*"
          variant="outlined"
          type="number"
          placeholder="Menge in Zahlen"
          className={classes.textField}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Field
              name="deliveryDeadline"
              component={renderTextField}
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Lieferfrist*"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="offersDeadline"
              component={renderTextField}
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Angebote Frist*"
              className={classes.textField}
            />
          </Grid>
        </Grid>
        <Typography variant="h4" component="h2" sx={{ mb: 1 }}>
          {"Spezifikationen"}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              name="material"
              component={RenderedAutocomplete}
              label="Material*"
              options={materials}
              changeFn={props.change}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              name="technology"
              component={RenderedAutocomplete}
              label="Technologie*"
              options={technologies}
              changeFn={props.change}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              name="certificate"
              component={RenderedAutocomplete}
              label="Zertifikat"
              options={certificates.map((certificate, i) => ({
                id: i,
                label: certificate,
              }))}
              changeFn={props.change}
            />
          </Grid>
        </Grid>
        <div className={classes.fileSection}>
          <Typography variant="h5" component="h2">
            {"Dateien hochladen"}
          </Typography>

          <Field
            name="files"
            component={FileInput}
            type="file"
            id="fileUpload"
            className={classes.uploadButton}
            multiple
          />
        </div>
      </div>
      <Divider />
      <div className={classes.bottomSection}>
        <div className={classes.buttonSection}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Auftrag hinzufügen"}
          </Button>
        </div>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "title",
    "description",
    "qty",
    "offersDeadline",
    "deliveryDeadline",
    "material",
    "technology",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });
  if (values.title && values.title.length > 60) {
    errors["title"] =
      "Zu lang! Der Titel darf nicht länger als 60 Zeichen sein";
  }
  // const nowDate = new Date();
  // Past Dates
  const dates = ["offersDeadline", "deliveryDeadline"];
  dates.forEach((date) => {
    if (new Date(values[date]) < new Date()) {
      errors[date] =
        "Ungültiges Datum (kann keine Daten in der Vergangenheit einstellen)";
    } else if (new Date(values[date]).getFullYear() > 2030) {
      errors[date] = "Ungültiges Datum";
    }
  });

  // offerDeadline > deliveryDeadline
  if (
    new Date(values["offersDeadline"]) > new Date(values["deliveryDeadline"])
  ) {
    errors["offersDeadline"] =
      "Ungültiges Datum (Angebotsfrist kann nicht nach der Lieferfrist liegen)";
  }

  // files validation
  if (values.files) {
    const files = values.files;

    for (let i = 0; i < files.length; i++) {
      if (files.length > 16) {
        errors["files"] =
          "Zu viele Dateien. Die maximal zulässige Anzahl von Dateien beträgt 16 Dateien.";
      }
      if (files[i].size > 25 * 1024 * 1024) {
        errors["files"] =
          "Zu große Datei. Die maximale Dateigröße beträgt 25 MB pro Datei.";
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: "AddOrdersForm", // a unique identifier for this form
  validate,
})(AddOrdersForm);

import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import companyNoLogo from "../../Assets/images/CompanyNoLogo.png";
import EditProfileDialog from "./EditProfileDialog.js";
import UpdateLogoDialog from "./UpdateLogoDialog.js";

import {
  getCompanyProfile,
  openEditDialog,
} from "../../redux/ducks/companyProfile";
import getDistance from "../../utils/getDistance";

import ExternalLink from "../shared/ExternalLink";
// MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import makeStyles from "@mui/styles/makeStyles";
//Icons
import BusinessIcon from "@mui/icons-material/Business";
import TodayIcon from "@mui/icons-material/Today";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactButton from "../shared/ContactButton";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  headerMain: {
    padding: "10px",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "200px",
    maxHeight: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    maxWidth: "100%",
  },
  table: { width: "100%" },
  subtitle: { display: "flex", alignItems: "center" },
  loadingDiv: {
    minHeight: "500px",
    marginTop: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  editButton: {
    marginRight: "1em",
    marginTop: "1em",
  },
}));

function CompanyProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { id } = useParams();
  const dateFormat = (date) => moment(date).format("DD/MM/YYYY");

  React.useEffect(() => {
    dispatch(getCompanyProfile(id));
  }, [dispatch, id]);

  const [openLogoDialog, setOpenLogoDialog] = React.useState(false);

  const { company, loading, loaded, error } = useSelector(
    (state) => state.companyProfile
  );
  const { company: myCompany } = useSelector((s) => s.auth);
  let companyCoordinates = "";
  let myCompanyCoordinates = "";
  let contactTableData = [];
  if (company) {
    contactTableData = [
      ...contactTableData,
      {
        key: "Straße, Nummer",
        value:
          company.address.street.streetName +
          " " +
          company.address.street.streetNum,
      },
      {
        key: "PLZ, Stadt",
        value: company.address.zip + " " + company.address.city,
      },
      { key: "Land", value: company.address.country },
      { key: "Firma Telefonnummer", value: company.phoneNumber },
      { key: "Firma Fax-Nummer", value: company.faxNumber },
      {
        key: "Webseite",
        value: company.website ? (
          <ExternalLink
            to={`${company.website}`}
          >
            {company.website}
          </ExternalLink>
        ) : (
          "-"
        ),
      },
    ];
    companyCoordinates = company.address.geometry.coordinates;
    myCompanyCoordinates = myCompany.address.geometry.coordinates;
  }

  return loaded ? (
    <div className={classes.root}>
      <Paper elevation={1}>
        <Grid
          container
          className={classes.header}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Hidden mdDown>
            <Grid item sm={2} onMouseOver={() => {}}>
              <Box
                sx={{
                  position: "relative",

                  "&:hover": {
                    "& div": {
                      display: "flex",
                    },
                  },
                  "& div": {
                    display: "none",
                  },
                }}
              >
                {company._id === myCompany._id && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      backgroundColor: "rgb(0,0,0,0.2)",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenLogoDialog(true);
                      }}
                      sx={{
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </div>
                )}

                <img
                  src={
                    company.logo ? `/files/${company.logo.name}` : companyNoLogo
                  }
                  alt="Company Logo"
                  width="100%"
                  //   height="100%"
                  className={classes.img}
                />
                <UpdateLogoDialog
                  open={openLogoDialog}
                  setOpen={setOpenLogoDialog}
                />
              </Box>
            </Grid>
          </Hidden>
          <Grid item className={classes.headerMain} flexGrow="1">
            <Typography variant="h3" component="h2">
              {company && company.name}
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              <Badge>
                <BusinessIcon />
              </Badge>
              {company && company.industryRole}
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              <TodayIcon /> {"Mitglied seit "}
              {company && dateFormat(company.createdAt)}
            </Typography>
            {company._id !== myCompany._id && (
              <Typography variant="subtitle1" className={classes.subtitle}>
                <LocationOnIcon />
                {company &&
                  `${
                    Math.round(
                      getDistance(
                        companyCoordinates[0],
                        companyCoordinates[1],
                        myCompanyCoordinates[0],
                        myCompanyCoordinates[1]
                      ) * 100
                    ) / 100
                  } KM von Ihnen entfernt`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {company._id === myCompany._id ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                className={classes.editButton}
                onClick={() => {
                  dispatch(openEditDialog());
                }}
              >
                {"Profil bearbeiten"}
              </Button>
            ) : (
              <ContactButton
                companyId={company._id}
                style={{ marginRight: "1em", marginTop: "1em" }}
              />
            )}

            <EditProfileDialog />
          </Grid>
        </Grid>
      </Paper>

      <Container>
        <Grid
          container
          className={classes.companyBody}
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Grid item xs={12} md={6} className={classes.section}>
            <Typography variant="h6">{"Kontaktinformationen"}</Typography>
            <table className={classes.table}>
              <tbody>
                {company &&
                  contactTableData.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Typography variant="subtitle2">
                            {row.key}:
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="subtitle1">
                            {row.value ? row.value : "-"}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              minHeight: "350px",
              mt: "16",
              mr: "0",
            }}
          >
            <Typography variant="h6">{"Firma Standort"}</Typography>
            <iframe
              title="Firma Standort"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDFQJZY6ZTsY1unkKyqvbM3LJJw5ZKFxGU&language=de&q=${company.address.street.streetName} ${company.address.street.streetNum},${company.address.city},${company.address.country}`}
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : loading ? (
    <div className={classes.loadingDiv}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.loadingDiv}>
      <Typography variant="h6">
        {"Es ist ein Fehler aufgetreten."}
        <br />
        {error && typeof error.message === "string" && error.message}
      </Typography>
    </div>
  );
}

export default CompanyProfile;

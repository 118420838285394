import React from "react";

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

// import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { createCheckOut, cleanUp } from "../../redux/ducks/unsubscribed";
import { logout } from "../../redux/ducks/auth";
import { openSnack } from "../../redux/ducks/snackbar";
import { Redirect } from "react-router-dom";

function Unsubscribed() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { checkoutURL, loading, error } = useSelector((s) => s.unsubscribed);

  React.useEffect(() => {
    if (checkoutURL) {
      window.location.href = checkoutURL;
    }
  }, [checkoutURL]);

  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);

  const subscription = useSelector((s) => s.auth.subscription);
  const companyRole = useSelector((s) => s.auth.company.industryRole);
  const companyName = useSelector((s) => s.auth.company.name);
  const companyId = useSelector((s) => s.auth.company._id);
  const aboName = companyRole === "Fertiger" ? "Fertiger Abo" : "Einkäufer Abo";
  const aboPrice = companyRole === "Fertiger" ? "299,00" : "0";
  const aboPeriod = companyRole === "Fertiger" ? "monatliches" : "jährliches";

  const handleSaveBillPayment = async (companyId, aboName, companyName) => {
    try {
      const response = await fetch('/api/saveBillPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyId, aboName, companyName }),
      });

      if (!response.ok) {
        throw new Error('Failed to save bill payment');
      }

      const data = await response.json();
      console.log('Bill payment saved successfully:', data);
    } catch (error) {
      console.error('Failed to save bill payment:', error);
      alert(`Error: ${error.message}`);
    }
  };

  

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     minHeight: "40vh",
    //   }}
    // >
    <Container
      sx={{
        mt: 3,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        "& > *": {
          my: "1em",
        },
      }}
    >
      {/* {companyRole !== "Fertiger" && <Redirect to="/companies/my" />} */}
      {subscription && <Redirect to="/companies/my" />}
      <Alert icon={false} severity="warning" sx={{ maxWidth: "60em" }}>
        <Typography>
          Es scheint, dass dieses Konto keine Abonnements hat. Um unsere
          Order-Scout-Dienste als {companyRole} nutzen zu können, benötigen Sie
          das {aboName}. Die Bedingungen des {aboName}s lauten wie folgt:
        </Typography>
      </Alert>
      <Paper
        sx={{
          maxWidth: "52.5em",
          p: "1.5em",
          // mt: "2em",
          "& > *": {
            my: "1em",
          },
        }}
      >
        <Typography variant="h4" style={{ textAlign: "center" }}>
          Abonnementvertrag
        </Typography>
        Zwischen
        <p style={{ marginLeft: "2em" }}>
          der <b>OS Order-Scout GmbH</b>, De-Gasperi-Straße 3 in 51465 Bergisch
          Gladbach, eingetragen beim Amtsgericht Köln, HRB 101012
        </p>
        und
        <b style={{ marginLeft: "2em", display: "block" }}>{companyName}</b>
        wird folgender Abonnementvertrag geschlossen:
        <p>
          Der {companyRole} verpflichtet sich, für die Nutzung der auf der
          Webseite www.order-scout.com durch OS Order-Scout bereitgestellten
          Plattform ein{" "}
          <b>
            {aboPeriod} Nutzungsentgelt in Höhe von {aboPrice} EUR (Netto) zzgl.
            der jeweils geltenden Umsatzsteuer
          </b>{" "}
          an OS Order-Scout zu zahlen.
        </p>{" "}
        <p>
          Die Vertragslaufzeit beträgt 12 Monate und verlängert sich im
          Anschluss um jeweils drei weitere Monate, sofern nicht eine der
          Parteien den Vertrag spätestens einen Monat vor Ablauf der
          Vertragslaufzeit kündigt. Für diesen Vertrag gelten ergänzend die
          Allgemeinen Geschäftsbedingungen von OS Order-Scout.
        </p>
      </Paper>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          variant="contained" color="primary"
          onClick={() => {
            dispatch(createCheckOut({ origin: window.location.origin }));
          }}
        >
          {!loading ? (
            "Bedingungen Akzeptieren und weiter zur Kasse"
          ) : (
            <CircularProgress color="inherit" size={30} />
          )}
        </Button>
      </div>      
      <div>
      <Button variant="contained" color="secondary"

      onClick={() => {                
        handleSaveBillPayment(companyId, aboName, companyName);   
        
        dispatch(logout());



      }}
    >
      {!loading ? (
        "Bedingungen Akzeptieren und auf Rechnung bestellen"
      ) : (
        <CircularProgress color="inherit" size={30} />
      )}
      </Button>
      </div>
    </Container>
  );
}

export default Unsubscribed;

import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// MUI
import { makeStyles } from "@mui/styles";
import { closeOfferDialog, sendOffer } from "../../../redux/ducks/orderView";

// import Button from "@mui/material/Button";
const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: "25ch",
  },
}));

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, invalid, error },
  ...props
}) => {
  const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files);
  return (
    <div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      <FormHelperText error>{touched && error}</FormHelperText>
    </div>
  );
};

let deliveryDeadline;

const SendOfferForm = ({ handleSubmit, loading }) => {
  deliveryDeadline = useSelector((state) => {
    return state.orderView.order;
  }).deliveryDeadline;
  const dispatch = useDispatch();
  let { id: orderID } = useParams();
  const classes = useStyles();

  return (
    <form
      onSubmit={handleSubmit((values) => {
        dispatch(sendOffer(orderID, values));
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Field
            name="deliveryDate"
            component={renderTextField}
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            label="Lieferdatum*"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="price"
            component={renderTextField}
            label="Preis*"
            variant="outlined"
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            type="number"
            fullWidth
          />
        </Grid>
      </Grid>
      <Field
        name="message"
        component={renderTextField}
        label="Nachricht"
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        className={classes.textField}
      />
      <Typography variant="h6">Dateihochladen</Typography>
      <Field
        name="files"
        component={FileInput}
        type="file"
        id="fileUpload"
        className={classes.uploadButton}
        multiple
      />
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeOfferDialog());
          }}
          color="primary"
        >
          {"Abbrechen"}
        </Button>
        <Button type="submit" color="primary" autoFocus disabled={loading}>
          {loading ? <CircularProgress /> : "Angebot Senden"}
        </Button>
      </DialogActions>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["deliveryDate", "price"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });
  if (values["price"] <= 0) {
    errors["price"] = "Der Preis muss höher sein als 0";
  }
  if (
    moment(values["deliveryDate"]).valueOf() >
    moment(deliveryDeadline).valueOf()
  ) {
    errors["deliveryDate"] =
      "Das Lieferdatum darf nicht nach der gewünschten Lieferfrist liegen.";
  }
  if (moment(values["deliveryDate"]).valueOf() < Date.now()) {
    errors["deliveryDate"] =
      "Das Lieferdatum darf nicht in der Vergangenheit liegen";
  }

  if (values.files) {
    const files = values.files;

    for (let i = 0; i < files.length; i++) {
      if (files.length > 16) {
        errors["files"] =
          "Zu viele Dateien. Die maximal zulässige Anzahl von Dateien beträgt 16 Dateien.";
      }
      if (files[i].size > 25 * 1024 * 1024) {
        errors["files"] =
          "Zu große Datei. Die maximale Dateigröße beträgt 25 MB pro Datei.";
      }
    }
  }
  return errors;
};

export default reduxForm({
  form: "SendOfferForm", // a unique identifier for this form
  validate,
})(SendOfferForm);
